<template>
  <div class="home">
    <myHeader :name="'首页'" />
    <myContact  />
    <div class="homeView">
      <el-carousel height="340px">
        <el-carousel-item v-for="item in imageList" :key="item" :interval="50000">
          <div style="position: relative;height: 100%;" class="cursor" @click="bannerNav(item)">
            <el-image :fit="'cover'" :src="item.resourceUrl?buseUrl+item.resourceUrl:logo" alt="" style="width: 100%;height: 100%;object-fit:cover;" />
            <p class="imgText">{{item.intro}}</p>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="content">
        <el-row :gutter="10" type="flex" justify="center" class="mt50">
          <el-col :sm="12" :span="24">
            <div class="news pd20 bg h600 activity">
              <div class="title_content">
                <h1>最新活动</h1>
                <div class="title_btn cursor" @click="$router.push('/industry')">更多>></div>
              </div>
              <div class="title_line"><span class="title_line_li"></span></div>
              <el-carousel
                trigger="none"
                arrow="never"
                indicator-position="outside"
                height="430px"
                class="mt35"
              >
                <el-carousel-item v-for="item in activityList" :key="item.id">
                  <div @click="$router.push({name:'detail',params:{id:item.id,type:'行业交流'}})">
                    <el-image :fit="'cover'" class="image" :src="item.coverPicUrl?buseUrl+item.coverPicUrl:logo" alt="" style="object-fit:cover;" />
                    <div style="padding: 20px 10px;">
                      <h2 style="margin-bottom: 20px;font-size: 24px;">{{ item.titleName }}</h2>
                      <p class="row_height text-overflow-one" style="font-size: 20px;color: #878787;">{{ item.intro }}</p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
          <el-col :sm="12" :span="24">
            <div class="news pd20 bg h600">
              <div class="title_content">
                <h1>新闻动态</h1>
                <div class="title_btn cursor" @click="$router.push('/industry')">更多>></div>
              </div>
              <div class="title_line"><span class="title_line_li"></span></div>
              <div class="news_list mt35">
                <div class="item" v-for="item in newsList" :key="item.id" @click="$router.push({name:'detail',params:{id:item.id,type:'行业交流'}})">
                  <div class="item_content">
                    <p class="single_warp title" style="font-weight: bold;font-size: 24px;padding: 0 0 11px 0;">
                      {{ item.titleName }}
                    </p>
                    <p class="multiLine_warp desc row_height text-overflow-one" style="font-size: 20px;color: #878787">
                      {{ item.intro }}
                    </p>
                  </div>
                  <div class="item_date">
                    <div class="date_day">{{ item.dayTime }}</div>
                    <div class="date_month">{{ item.monthTime }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="mt50 video_control" v-if="vedioList">
          <video ref="video" :src="buseUrl+vedioList" style="width: 100%;height: 100%;"></video>
          <div class="video_play cursor">
            <i class="el-icon-video-play" v-if="videoPlay" @click="play"></i>
            <i class="el-icon-video-pause" v-else @click="pause"></i>
          </div>
        </div>
        <el-row :gutter="10" type="flex" justify="center" class="mt50">
          <el-col :sm="12" :span="24">
            <div class="news pd20 bg" style="height:565px;">
              <div class="title_content">
                <h1>理论成果</h1>
                <div class="title_btn cursor" @click="$router.push('/theory')">更多>></div>
              </div>
              <div class="title_line"><span class="title_line_li"></span></div>
              <div class="cursor mt35">
                <div v-if="theoryListImg" style="height: 200px;margin-bottom: 10px;" @click="$router.push({name:'detail',params:{id:theoryList[0].id,type:'理论研究'}})">
                  <el-image lazy :fit="'cover'" :src="theoryListImg?buseUrl+theoryListImg:logo" alt="" style="object-fit:cover;" />
                </div>
                <div v-for="item in theoryList" :key="item.id" @click="$router.push({name:'detail',params:{id:item.id,type:'理论研究'}})">
                  <p style="padding: 10px 0;font-size: 20px;">· {{ item.titleName }}</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :sm="12" :span="24">
            <div class="news pd20 bg" style="height:565px;">
              <div class="title_content">
                <h1>教育培训</h1>
                <div class="title_btn cursor" @click="$router.push('/train')">更多>></div>
              </div>
              <div class="title_line"><span class="title_line_li"></span></div>
              <div class="cursor mt35">
                <div v-if="educationListImg" style="height: 200px;margin-bottom: 10px;" @click="$router.push({name:'detail',params:{id:educationList[0].id,type:'教育培训'}})">
                  <el-image lazy :fit="'cover'" :src="educationListImg?buseUrl+educationListImg:logo" alt="" style="object-fit:cover;" />
                </div>
                <div v-for="item in educationList" :key="item.id" @click="$router.push({name:'detail',params:{id:item.id,type:'教育培训'}})">
                  <p style="padding: 10px 0;font-size: 20px;">· {{ item.titleName }}</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 会员风采 -->
        <div class="news mt50 pd20 bg cursor">
          <div class="title_content">
            <h1>会员风采</h1>
            <div class="title_btn cursor" @click="$router.push('/member')">更多>></div>
          </div>
          <div class="title_line"><span class="title_line_li"></span></div>
          <el-carousel
            trigger="none"
            arrow="arrow"
            indicator-position="outside"
            height="300px"
            class="mt35"
          >
            <template v-for="item in memberList">
              <el-carousel-item :key="item.id" v-if="item.list.length>=1">
                <div class="cursor flex just-between" style="justify-content: start !important;column-gap: 14px;">
                  <div v-for="i in item.list" :key="i.id" class="cursor course" @click="$router.push({name:'detail',params:{id:i.id,type:'会员风采'}})">
                    <el-image :fit="'cover'" :src="i.coverPicUrl?buseUrl+i.coverPicUrl:logo" alt="" style="width: 100%;height: 156px;object-fit:cover;" />
                    <div style="padding: 20px 10px;">
                      <h1 style="margin-bottom: 20px;font-size: 20px;">{{ i.titleName }}</h1>
                      <p class="row_height text-overflow-one" style="font-size: 18px;">{{ i.intro }}</p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </template>
          </el-carousel>
        </div>
        <!-- 公开课 -->
        <div class="news mt50 pd20 bg">
          <div class="title_content">
            <h1>公开课</h1>
            <!-- <el-button size="small" type="primary" @click="$router.push('/industry')">更多 > ></el-button> -->
          </div>
          <div class="title_line"><span class="title_line_li"></span></div>
          <div class="flex just-between cursor mt35" style="justify-content: start !important;column-gap: 14px;">
            <div v-for="item in courseList" :key="item.id" class="course" @click="OpenClassesNav(item)">
              <div style="height: 156px;">
                <el-image lazy :fit="'cover'" :src="item.coverUrl?buseUrl+item.coverUrl:logo" alt="" style="width:100%;height:100%;object-fit:cover;" />
              </div>
              <div style="padding: 20px 10px;">
                <h1 class="text-overflow-one" style="margin-bottom: 20px;font-size: 20px;">{{ item.className }}</h1>
                <p class="row_height text-overflow-one" style="font-size: 18px;">{{ item.intro }}</p>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import myHeader from '@/components/my-header.vue'
import myFooter from '@/components/my-footer.vue'
import myContact from '@/components/my-contact.vue'
import { queryPromotion, queryActivity, queryNews, queryVedio, queryTheoryManager, queryEducation, queryMemberGroup, queryPublicClass } from '@/api/home'
export default {
  name: 'HomeView',
  components: {myHeader,myFooter,myContact},
  data() {
    return {
      logo:require('@/assets/logo.png'),
      buseUrl:process.env.VUE_APP_BACKEND_URL+'/api/file',
      videoPlay: true,
      // 最新活动
      activityList: [],
      vedioList:'',
      imageList:[],
      imageList2: [],
      // 新闻动态
      newsList: [],
      theoryList:[],
      theoryListImg:'',
      educationList:[],
      educationListImg:'',
      courseList: [],
      memberList: [],
    }
  },
  mounted() {
    let area = JSON.parse(localStorage.getItem('area'))?JSON.parse(localStorage.getItem('area')):{areaCode:'',areaName:''}
    this.queryPromotion(area)
    this.queryActivity(area)
    this.queryNews(area)
    this.queryVedio(area)
    this.queryTheoryManager(area)
    this.queryEducation(area)
    this.queryMemberGroup(area)
    this.queryPublicClass(area)
  },
  methods: {
    async queryPromotion(area) {
      const res = await queryPromotion(area)
      this.imageList = res.data.promotion
    },
    async queryActivity(area) {
      const res = await queryActivity(area)
      this.activityList = res.data.activity
    },
    async queryNews(area) {
      const res = await queryNews(area)
      this.newsList = res.data.news
    },
    async queryVedio(area) {
      const res = await queryVedio(area)
      this.vedioList = res.data.vedio.resourceUrl
    },
    async queryTheoryManager(area) {
      const res = await queryTheoryManager(area)
      this.theoryList = res.data.theory
      this.theoryListImg = res.data.theory[0].coverPicUrl
    },
    async queryEducation(area) {
      const res = await queryEducation(area)
      this.educationList = res.data.education
      this.educationListImg = res.data.education[0].coverPicUrl
    },
    async queryMemberGroup(area) {
      const res = await queryMemberGroup(area)
      this.memberList = [...res.data.member]
    },
    async queryPublicClass(area) {
      const res = await queryPublicClass(area)
      this.courseList = res.data.publicClass
    },
    bannerNav(row){//轮播图点击跳转
      if(row.linkUrl){
        if(row.linkType==1){//内部链接
          this.$router.push(row.linkUrl)
        }else if(row.linkType==2){//外部链接
          window.open(row.linkUrl,'_blank')
        }
      }
    },
    OpenClassesNav(row){//公开课跳转
      window.open(row.classUrl,'_blank')
    },
    // 播放
    play() {
      this.$refs.video.play();
      this.videoPlay = false;
    },
    // 暂停
    pause() {
      this.$refs.video.pause();
      this.videoPlay = true;
    },
  }
}
</script>
<style lang="scss" scoped>
.home {
  background-color: #f7f8fc;
}
img{
  width: 100%;
  height: 100%;
}
.mt50{
  margin-top: 50px;
}
.pd20{
  padding: 20px;
}
.mt35{
  margin-top: 35px;
}
.bg{
  background-color: #fff;
}
.h600{
  height: 580px;
}
h1{
  font-size: 26px;
}
.title_line{
  width: 100%;
  height: 1px;
  background-color: #F1F1F1;
  position: relative;
  .title_line_li{
    position: absolute;
    width: 53px;
    height: 3px;
    left: 0;
    top: -1px;
    background-color: #012EA7;
  }
}
.title_btn{
  width: 90px;
  height: 34px;
  background: #002EA6;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  line-height: 34px;
  color: #fff;
}
.row_height{
  -webkit-line-clamp: 3 !important;
}
.homeView {
  margin-bottom: 20px;
}
.imgText{
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  color: #fff;
  font-size: 20px;
  background: rgba(0,0,0,0.3);
  padding: 5px 20px;
  border-radius: 5px;
  border: 0.5px solid #fff;
}
.content{
  width: 950px;
  margin: auto;

  .activity {
      .image {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
      .el-carousel__item {
        cursor: pointer;
        .article {
          .article_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              color: #999999;
              font-size: 14px;
              width: 80px;
            }
            p {
              flex: 1;
              font-size: 18px;
            }
          }
          .article_text {
            p {
              color: #999999;
              font-size: 16px;
            }
          }
        }
      }
    }
  .news {
    .title_content{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    .news_list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 20px;
      height: 300px;
      .item {
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 10px;
        .single_warp{
          padding: 10px 0;
        }
        .item_date {
          width: 100px;
          text-align: center;
          align-self: center;
          .date_day {
            font-size: 26px;
            font-weight: bold;
          }
          .date_month {
            font-size: 16px;
          }
        }
        .item_content {
          flex: 1;
          .title {
            font-size: 18px;
          }
          p {
            font-size: 16px;
          }
          .desc {
            -webkit-line-clamp: 3 !important;
          }
        }
      }
    }
  }
  .video_control {
      position: relative;
      video {
        width: 100%;
      }
      .video_play {
        position: absolute;
        right: 20px;
        bottom: 20px;
        font-size: 50px;
      }
    }
  .course{
    width: 216px;
    height: 300px;
  }
}
</style>
